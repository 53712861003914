<template>
  <div class="ml-5 pl-4 fwd-display">
    <div v-for="mail in fwdMail" :key="mail.parentId">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" class="py-0" order-md="first" order="last">
            <mail-header
              :mailObject="mail"
              :collapseView="true"
              :showIds="true"
            ></mail-header>
          </v-col>

          <v-col cols="12" md="6" class="py-0 d-none d-md-block text-right">
            <time-format :timestamp="mail.timestamp"></time-format>
          </v-col>
        </v-row>
      </v-container>

      <attachment-view
        v-if="mail.files && mail.files.length"
        :files="mail.files"
        :randomString="randomString"
      ></attachment-view>

      <div class="mail-body py-5">
        <!-- {{ mail.body }} -->
        <template v-if="mail.body.slice(0, 8) == matchString">
          <view-rich-text :delta="mail.body" :mailObj="mail"></view-rich-text>
        </template>
        <template v-else>
          {{ mail.body }}
        </template>
      </div>

      <v-layout class="grey lighten-1 pt-1 mb-2"></v-layout>
    </div>
  </div>
</template>

<script>
import attachmentView from "./attachment-view";
import ViewRichText from "../text-editor/ViewRichText.vue";

import mailHeader from "./fields/mail-header";
import timeFormat from "./components/time-format";

export default {
  props: ["fwdMail", "randomString", "matchString"],

  components: { attachmentView, ViewRichText, mailHeader, timeFormat },

  data: () => {
    return {
      dialog: false,
      iframeLoading: true,
      attachments: [],
      iframe: {
        name: "",
        src: "",
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    getTimeToShow(time) {
      return new Date(time)
        .toGMTString()
        .split(` ${new Date(time).getFullYear()}`)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.fwd-display {
  // border-left: 1px solid #00000024;
  border-left: 2px solid #bdbdbd;
}
.file-viewer {
  width: 80vw;
  height: 80vh;
  position: relative;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 65vh;
    overflow: auto;

    img {
      height: auto;
      flex-shrink: 0;
      max-width: none;
      user-select: none;
      height: 100%;
    }
  }
}

.featured-button {
  color: #5f6368 !important;
  box-shadow: inset 0 0 0 1px #dadce0 !important;
}
</style>
