<template>
  <div>
    {{ getTimeToShow(new Date(timestamp)) }}
    <span class="text-uppercase">
      {{
        new Date(timestamp).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["timestamp"],
  methods: {
    getTimeToShow(time) {
      return new Date(time)
        .toGMTString()
        .split(` ${new Date(time).getFullYear()}`)[0];
    },
  },
};
</script>
