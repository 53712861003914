var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-1 border",attrs:{"fluid":"","color":_vm.$store.getters.theme === 'dark' ? '#000000' : '#ffffff',"dark":_vm.$store.getters.theme === 'dark' ? true : false}},[_c('v-row',[_c('v-spacer'),_c('v-col'),_c('v-col',{staticClass:"box",class:_vm.$store.getters.theme === 'dark'
                    ? 'arrow-right-dark'
                    : 'arrow-right-light',style:({
                'background-color':
                    _vm.$store.getters.theme === 'dark'
                        ? '#000000cc'
                        : '#ffffffcc',
            }),attrs:{"cols":"10"}},[(_vm.mailObject.files && _vm.mailObject.files.length)?_c('attachment-view',{attrs:{"files":_vm.mailObject.files,"randomString":_vm.randomString}}):_vm._e(),(!_vm.isDecrypting)?_c('div',{staticStyle:{"width":"100%","overflow-wrap":"break-word"}},[(_vm.mailObject.body.slice(0, 8) == _vm.matchString)?_c('div',{staticClass:"pb-2 mb-1"},[_c('view-rich-text',{attrs:{"delta":_vm.mailObject.body,"mailObj":_vm.mailObject}})],1):_c('div',{staticClass:"pb-2 mb-1"},[_vm._v(" "+_vm._s(_vm.mailObject.body)+" ")]),(_vm.mailObject.mailType == 'forwardMail')?_c('view-forward-mails',{attrs:{"fwdMail":_vm.mailObject.forwardMails,"randomString":_vm.mailObject.randomString,"matchString":_vm.matchString}}):_vm._e(),_c('v-layout',{attrs:{"align-baseline":""}},[_c('v-spacer'),_c('mail-footer',{attrs:{"mailObject":_vm.mailObject}}),_vm._v("   | "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","x-small":"","dark":_vm.$store.getters.theme === 'dark'
                                        ? true
                                        : false,"target":"_blank"},on:{"click":function($event){return _vm.$emit('openForwordMail')}}},on),[_c('v-icon',[_vm._v("mdi-share")])],1)]}}],null,false,3532169385)},[_c('span',[_vm._v("Forward")])])],1)],1):_c('loading-message',{attrs:{"message":"Decrypting mail"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{staticClass:"main-btn ml-3",attrs:{"size":"40"}},[_c('strong',[_vm._v(_vm._s(_vm.mailObject.sender ? _vm.mailObject.sender.slice(1, 3).toUpperCase() : "##"))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }