<template>
    <section>
        <v-row
            v-if="!isDecrypting"
            :justify="
                [userInfo.nickName]
                    .concat(userInfo.domains)
                    .includes(mailObject.sender)
                    ? 'start'
                    : 'end'
            "
        >
            <v-col
                class="pb-0"
                cols="10"
                v-if="
                    ![userInfo.nickName]
                        .concat(userInfo.domains)
                        .includes(mailObject.sender)
                "
            >
                <single-mail-left
                    :mailObject="mailObject"
                    :showIds="true"
                    :isDecrypting="isDecrypting"
                    :randomString="randomString"
                    :collapse="true"
                    @openReply="openReply"
                    @openForwordMail="$emit('openForwordMail', index)"
                ></single-mail-left>
            </v-col>
            <v-col cols="10" class="pb-0" v-else>
                <single-mail-right
                    :mailObject="mailObject"
                    :showIds="true"
                    :isDecrypting="isDecrypting"
                    :randomString="randomString"
                    :collapse="true"
                    @openReply="openReply"
                    @openForwordMail="$emit('openForwordMail', index)"
                ></single-mail-right>
            </v-col>
        </v-row>
        <loading-message
            message="Decrypting mail"
            class="mt-6"
            v-else
        ></loading-message>
    </section>
</template>

<script>
// import attachmentView from "./attachment-view.vue";

// import viewForwardMails from "./forward-view.vue";
// import mailHeader from "./fields/mail-header.vue";
// import timeFormat from "./components/time-format.vue";

import loadingMessage from "./components/loading-message";
// import ViewRichText from "../text-editor/ViewRichText";

import singleMailRight from "./view-single-mail-right";
import singleMailLeft from "./view-single-mail-sender.vue";

export default {
    props: [
        "mailObject",
        "isDecrypting",
        "showIds",
        "randomString",
        "collapse",
    ],
    data: () => ({
        showCollapse: false,
        matchString: `{"ops":[`,
    }),
    created() {
        this.showCollapse = this.collapse ? this.collapse : false;
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
    },
    components: {
        // ViewRichText,
        // attachmentView,
        // viewForwardMails,
        loadingMessage,
        // mailHeader,
        // timeFormat,
        singleMailRight,
        singleMailLeft,
    },
    methods: {
        openReplySingle() {
            const { sender, receiver, cc, subject, domainHolders } =
                this.mailObject;

            this.$emit("openReply", {
                type: "single",
                mailObj: { sender, receiver, cc, subject, domainHolders },
            });
        },
        openReply(type = { type: "all" }) {
            this.$emit("openReply", type);
        },
        openReplyAll() {
            const { sender, receiver, cc, subject, domainHolders } =
                this.mailObject;

            this.$emit("openReply", {
                type: "all",
                mailObj: { sender, receiver, cc, subject, domainHolders },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.fit-text {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mail-collapse {
    font-size: 0.8em;
}
</style>
