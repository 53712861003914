<template>
  <section>
    <v-dialog v-model="showDetails" max-width="max-content">
      <popup-details :mailDetails="mailObject"></popup-details>
    </v-dialog>
    <v-layout align-center>
      <v-avatar
        size="40"
        :dark="$store.getters.theme === 'dark' ? true : false"
        class="mr-2"
        style="border: 1px solid"
      >
        <v-icon class="" :dark="$store.getters.theme === 'dark' ? true : false">
          mdi-account
        </v-icon>
      </v-avatar>

      <!-- <span class="mail-details"> -->
      <span class="mail-details" :class="collapseView ? 'mail-collapse' : ''">
        <span class="py-0 pr-2">
          <h2 class="font-weight-regular max-con subject">
            {{ mailObject.subject }}
            <span class="max-text name-show max-con">
              <span class="from" :title="getSingleUserInfo(mailObject.sender)">
                From: {{ mailObject.sender }}
              </span>
            </span>
          </h2>
          <span class="max-text name-show max-con">
            <template
              v-if="mailObject.receiver && mailObject.receiver.length > 1"
            >
              To:
              <span
                v-for="(item, index) in mailObject.receiver"
                :title="getSingleUserInfo(item)"
                :key="index"
              >
                {{ item }}
                {{ mailObject.receiver.length - 1 > index ? ", " : "" }}
              </span>
            </template>
            <template v-else>
              <span :title="getSingleUserInfo(mailObject.receiver[0])">
                To: {{ mailObject.receiver[0] }}
              </span>
            </template>
          </span>
        </span>
      </span>
      <v-btn
        @click="showDetails = true"
        :dark="$store.getters.theme === 'dark' ? true : false"
        small
        min-width="auto"
        elevation="0"
        color="transparent"
        class="pa-0 ml-2"
        title="view mail details"
      >
        <v-icon> mdi-menu-down </v-icon>
      </v-btn>
    </v-layout>
  </section>
</template>

<script>
import popupDetails from "./PopupDetails.vue";

export default {
  props: ["mailObject", "showIds", "otherSide", "collapseView"],
  components: { popupDetails },

  data: () => ({
    showDetails: false,
    isFwd: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userNames() {
      return this.$store.getters.userNames;
    },
    // getSenderInfo() {
    //   return this.getSingleUserInfo(this.mailObject.sender);
    // },
    // getReceiverInfo() {
    //   if (this.mailObject.receiver.length == 1)
    //     return this.getSingleUserInfo(this.mailObject.receiver[0]);

    //   let receiver = "";

    //   for (let index = 0; index < this.mailObject.receiver.length; index++) {
    //     receiver =
    //       this.getSingleUserInfo(this.mailObject.receiver[index]) + ", ";
    //   }

    //   return receiver.slice(0, -2);
    // },
    // getOtherSide() {
    //   if (this.mailObject.sender == this.userInfo.nickName)
    //     return `To: ${this.mailObject.receiver
    //       .toString()
    //       .split(",")
    //       .join(", ")}`;
    //   return `From: ${this.mailObject.sender}`;
    // },
  },

  methods: {
    getSingleUserInfo(lookUpName) {
      if (!lookUpName) return "";

      if (lookUpName == this.userInfo.nickName)
        return "Me <" + lookUpName + ">";
      if (this.userInfo.domains.includes(lookUpName))
        return "Me <" + lookUpName + ">";

      lookUpName = lookUpName.toLowerCase();

      const senderDetails = this.userNames[lookUpName];

      if (
        !senderDetails ||
        !senderDetails.firstName ||
        senderDetails.firstName == ""
      )
        return "<" + lookUpName + ">";

      return (
        senderDetails.firstName +
        " " +
        senderDetails.lastName +
        " " +
        "<" +
        lookUpName +
        ">"
      );
    },
    getUserName(email) {
      if (!email) return;
      if (email == this.userInfo.emailId) return "me";
      const user = [];

      if (!user.length) return email;
      return user[0].firstName + " " + user[0].lastName;
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-collapse {
  .subject {
    font-size: 20px;
    .form {
      font-size: 16px;
    }
  }
}

.name-show {
  position: relative;
  font-size: 14px;
}

.max-con {
  width: max-content;
  max-width: 50vw;
}
.max-text {
  position: relative;
  font-size: 14px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: sub;
}
</style>
