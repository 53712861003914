<template>
  <v-container
    class="pa-0 mb-10"
    fluid
    :class="fullscreen ? 'lg-compose-wrapper' : 'sm-compose-wrapper'"
  >
    <new-compose
      :type="'replyMail'"
      :disableMultiSender="true"
      :mData="rData"
      :defaultTitle="'Reply Mail'"
      :fullscreen="fullscreen"
      @fullscreen="fullscreen = !fullscreen"
      @discard="discard"
    ></new-compose>
  </v-container>
</template>

<script>
import NewCompose from "./newCompose.vue";

export default {
  props: ["rData"],
  components: { NewCompose },

  data() {
    return {
      fullscreen: false,
    };
  },

  methods: {
    discard(routeBackAfterSent) {
      if (routeBackAfterSent) this.$router.go(-1);
      this.rData.openReply = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sm-compose-wrapper {
  height: 400px;
}
.lg-compose-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: calc(100vh - 52px);
  width: 100vw;
}
</style>
