<template>
    <main style="height: 100%">
        <div>
            <!-- :class="
          $store.getters.theme === 'dark' ? '' : 'primary lighten-1'
        " -->
            <v-layout
                class="px-4 py-1 m-title main-btn"
                :color="$store.getters.theme === 'dark' ? 'white' : 'black'"
                align-center
                justify-space-between
                style="height: 50px"
            >
                <div class="max-text">
                    {{ title && title.length ? title : defaultTitle }}
                </div>

                <div>
                    <div style="width: max-content">
                        <v-btn
                            :dark="
                                $store.getters.theme === 'dark' ? true : false
                            "
                            icon
                            fab
                            x-small
                            elevation="0"
                            @click="$emit('fullscreen')"
                        >
                            <v-icon v-if="fullscreen">
                                mdi-fullscreen-exit
                            </v-icon>
                            <v-icon v-else> mdi-fullscreen </v-icon>
                        </v-btn>

                        <v-btn
                            :dark="
                                $store.getters.theme === 'dark' ? true : false
                            "
                            icon
                            fab
                            x-small
                            elevation="0"
                            @click="$emit('discard', routeBackAfterSent)"
                        >
                            <v-icon> mdi-close </v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-layout>
        </div>
        <v-card style="height: 100%" elevation="5" :disabled="submitDisable">
            <v-form
                style="height: 100%"
                lazy-validation
                ref="composeMail"
                @submit.prevent
            >
                <v-layout column fill-height>
                    <div>
                        <v-input hide-details>
                            <div class="ml-4" v-show="to.length">To:</div>
                            <!-- <v-combobox
                v-model.trim="params.receivers"
                label="Receivers"
                multiple
                flat
                solo
                chips
                deletable-chips
                id="compose-input-to"
                hide-details
              >
              </v-combobox> -->

                            <v-combobox
                                :menu-props="{ closeOnContentClick: true }"
                                v-model.trim="to"
                                :search-input.sync="searchToMails"
                                :items="autoFetchDetails"
                                item-text="name"
                                item-value="name"
                                label="Receivers"
                                class="compose-form--input"
                                multiple
                                hide-selected
                                flat
                                solo
                                hide-details
                                chips
                                deletable-chips
                            >
                            </v-combobox>

                            <a
                                v-show="!showCC"
                                class="mr-4"
                                @click="showCC = true"
                            >
                                Cc
                            </a>
                        </v-input>
                        <v-divider></v-divider>
                    </div>

                    <div v-if="showCC">
                        <v-input hide-details>
                            <div class="ml-4" v-show="cc.length">Cc:</div>
                            <!-- v-model.trim="params.cc"
                label="Cc"
                multiple
                flat
                solo
                chips
                id="compose-input-cc"
                hide-details -->
                            <v-combobox
                                :menu-props="{ closeOnContentClick: true }"
                                v-model.trim="cc"
                                :search-input.sync="searchToCc"
                                :items="autoFetchDetails"
                                label="CC"
                                class="compose-form--input"
                                item-text="name"
                                item-value="name"
                                multiple
                                hide-selected
                                flat
                                solo
                                v-if="showCC"
                                hide-details
                                chips
                                deletable-chips
                            >
                            </v-combobox>
                        </v-input>
                        <v-divider></v-divider>
                    </div>

                    <div>
                        <v-text-field
                            class="white compose-form--input"
                            solo
                            flat
                            v-model.trim="subject"
                            :hide-details="
                                subject
                                    ? subject.length <= 300
                                        ? true
                                        : false
                                    : true
                            "
                            :rules="subjectRule"
                            label="Subject"
                            counter="300"
                            @keydown.enter.prevent
                        ></v-text-field>
                        <v-divider></v-divider>
                    </div>

                    <div>
                        <add-attachments
                            :inputAttachments="file.inputAttachments"
                            :totalAttachmentSize="file.attachmentsSize"
                            :randomString="randomString"
                            :resetFlag="resetFlag"
                            @totalFileSize="file.attachmentsSize = $event"
                        ></add-attachments>
                        <v-divider
                            v-if="file.inputAttachments.length > 0"
                        ></v-divider>
                    </div>

                    <!-- <div style="flex: 1; overflow-y: auto"> -->
                    <div style="flex: 1; position: relative">
                        <rich-text-editor
                            :resetFlag="resetFlag"
                            :bodyContent="bodyContent"
                        ></rich-text-editor>
                    </div>

                    <div class="my-2">
                        <v-layout
                            align-center
                            justify-space-between
                            class="compose-form--footer px-3 py-1"
                        >
                            <!-- :disabled="!formValid" -->
                            <v-btn
                                :loading="submitDisable"
                                color="primary"
                                id="compose-btn-submit"
                                class="white--text"
                                type="submit"
                                @click="submit()"
                            >
                                Send
                            </v-btn>
                            <v-text-field
                                outlined
                                class="compose-sen mx-1"
                                width="50"
                                dense
                                flat
                                v-model.trim="sender"
                                hide-details
                                label="From"
                                readonly
                                @keydown.enter.prevent
                                v-if="disableMultiSender"
                            ></v-text-field>

                            <v-select
                                v-else
                                width="max-content"
                                class="compose-sen mx-1"
                                hide-details
                                v-model="sender"
                                :items="
                                    [userInfo.nickName].concat(userInfo.domains)
                                "
                                dense
                                label="From"
                                outlined
                            ></v-select>

                            <v-btn
                                icon
                                @click="chooseFiles"
                                title="Add File"
                                id="compose-btn-paperclip"
                                class="pa-0 mx-auto"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <!-- <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="pa-0 mr-auto">
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span> Coming Soon </span>
              </v-tooltip> -->

                            <v-btn
                                icon
                                @click="clearForm()"
                                :disabled="submitDisable"
                                id="compose-btn-refresh"
                                title="Clear"
                                v-if="
                                    !(
                                        type == 'forwardMail' ||
                                        type == 'replyMail'
                                    )
                                "
                            >
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>

                            <v-btn
                                icon
                                @click="closeForm()"
                                :disabled="submitDisable"
                                id="compose-btn-close"
                                title="Discord"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-layout>
                    </div>
                </v-layout>
            </v-form>
        </v-card>
        <v-dialog v-model="recipientNotFoundErrorDialog" max-width="600">
            <v-card>
                <recipient-not-found
                    @closeRecipientNotFoundDialog="
                        recipientNotFoundErrorDialog = false
                    "
                ></recipient-not-found>
            </v-card>
        </v-dialog>
    </main>
</template>

<script>
import { EventBus } from "@/plugins/event-bus";

import addAttachments from "../attachments/add-attachments.vue";
import RichTextEditor from "@/views/mail/text-editor/RichTextEditor.vue";
import RecipientNotFound from "../errors/recipient-not-found.vue";
let titleDebounceTimer;

export default {
    components: { RichTextEditor, addAttachments, RecipientNotFound },
    props: [
        "mData",
        "type",
        "fullscreen",
        "defaultTitle",
        "disableMultiSender",
    ],

    created() {
        this.sender = this.userInfo.nickName;
        this.params.mailType = this.type;

        if (this.type === "newMail") {
            this.randomString = this.generateRandomWords;
        }

        if (this.type === "replyMail") {
            this.randomString = this.mData.randomString;
            this.params.parentId = this.mData.parentId;
            // this.params.receivers = this.mData.receivers;
            // this.params.cc = this.mData.cc;

            // if (this.params.cc.length) this.showCC = true;

            // this.params.subject =
            //   this.mData.subject.substring(0, 4) == "Re: "
            //     ? this.mData.subject
            //     : "Re: " + this.mData.subject;

            const rData = { ...this.mData };
            let temp = Array.from(new Set(rData.receivers));
            let tempReceivers = [];
            let tempCcs = [];

            for (const key in rData.domainHolders) {
                tempReceivers = tempReceivers.concat(
                    rData.domainHolders[key].map((a) => a.receiver)
                );
                tempReceivers = tempReceivers.concat(
                    rData.domainHolders[key].map((a) => a.sender)
                );

                tempCcs = tempCcs.concat(
                    rData.domainHolders[key].map((a) => a.cc)
                );
            }

            this.to = temp.map((data) => {
                if (!tempReceivers.includes(data)) return { name: data };

                for (const key in rData.domainHolders) {
                    let length = rData.domainHolders[key].length;

                    for (let index = 0; index < length; index++) {
                        if (rData.domainHolders[key][index].receiver == data)
                            return { name: data, holder: key };
                        if (rData.domainHolders[key][index].sender == data)
                            return { name: data, holder: key };
                    }
                }
            });

            temp = Array.from(new Set(rData.cc));
            this.cc = temp.map((data) => {
                if (!tempCcs.includes(data)) return { name: data };

                for (const key in rData.domainHolders) {
                    let length = rData.domainHolders[key].length;

                    for (let index = 0; index < length; index++)
                        if (rData.domainHolders[key][index].cc == data)
                            return { name: data, holder: key };
                }
            });

            if (this.cc.length) this.showCC = true;

            this.subject =
                rData.subject.substring(0, 4) == "Re: "
                    ? rData.subject
                    : "Re: " + rData.subject;

            this.sender = rData.sender;

            this.to = this.to.filter((a) => {
                if (a.name !== this.sender) return a;
            });
        }

        if (this.type === "forwardMail") {
            this.randomString = this.mData.randomString;
            this.params.previousMails = this.mData.previousMails;

            // this.params.subject =
            //   this.mData.subject.substring(0, 5) === "Re: "
            //     ? this.mData.subject
            //     : "Fwd: " + this.mData.subject;

            const fData = { ...this.mData };

            this.subject =
                fData.subject.substring(0, 5) == "Fwd: "
                    ? fData.subject
                    : "Fwd: " + fData.subject;
            this.sender = this.userInfo.nickName;

            let isSender = -1;
            if (fData.domainHolders[this.userInfo.nickName])
                for (
                    let index = 0;
                    index < fData.domainHolders[this.userInfo.nickName].length;
                    index++
                ) {
                    if (
                        fData.domainHolders[this.userInfo.nickName][index]
                            .sender
                    ) {
                        isSender = index;
                        this.sender =
                            fData.domainHolders[this.userInfo.nickName][
                                index
                            ].sender;
                    }
                }

            if (
                fData.domainHolders &&
                fData.domainHolders[this.userInfo.nickName] &&
                isSender === -1
            ) {
                this.sender = fData.domainHolders[this.userInfo.nickName][0]
                    .receiver
                    ? fData.domainHolders[this.userInfo.nickName][0].receiver
                    : fData.domainHolders[this.userInfo.nickName][0].cc;
            } else if (this.userInfo.nickName == fData.sender) {
                this.sender = this.userInfo.nickName;
            }
        }
    },

    data: () => ({
        bodyContent: {
            delta: undefined,
            messageLength: 0,
        },

        dummy: true,

        searchToMails: null,
        searchToCc: null,
        to: [],
        domainHolderMap: {},

        showCC: false,
        randomString: "",
        errorMessage: "",
        file_size: 0,

        submitDisable: false,
        routeBackAfterSent: false,
        recipientNotFoundErrorDialog: false,

        resetFlag: false,
        title: "",

        sender: "",
        receiver: [],
        ccReceiver: [],
        subject: "",
        body: "",
        attached: [],

        cc: [],
        selected: [],
        inputAttachments: [],

        params: {
            sender: "",
            receivers: [],
            cc: [],
            subject: "",
            body: "",
            mailType: "",
            previousMails: [],
            parentId: "",
            attachments: [],
        },

        file: {
            inputAttachments: [],
            attachmentsSize: 0,
        },

        rules: {
            emailArray: [
                (v) =>
                    /^(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3}))(,(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})))*$/.test(
                        v
                    ) || "Invalid Email(s)",
            ],
        },

        subjectRule: [
            (v) => !!v || "Subject is required",
            (v) =>
                (v && v.length <= 300) ||
                "Subject must be less than 300 characters",
        ],
    }),

    watch: {
        subject(val) {
            clearTimeout(titleDebounceTimer);
            titleDebounceTimer = setTimeout(() => (this.title = val), 1000);
        },

        searchToMails(val) {
            if (!val) return;
            this.fetchEntriesDebounced(val);
        },
        searchToCc(val) {
            if (!val) return;
            this.fetchEntriesDebounced(val);
        },

        receiver(val) {
            if (!val) return;
            this.searchToMails = null;
        },
        ccReceiver(val) {
            if (!val) return;
            this.searchToCc = null;
        },

        inputAttachments(newFiles) {
            this.addAttachments(newFiles);
        },
    },

    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
        generateRandomWords() {
            return (
                Math.random().toString(36).substring(7) +
                Math.random().toString(36).substring(7)
            );
        },

        autoFetchDetails() {
            return this.$store.getters.autoFetch;
        },
        formValid() {
            if (!this.to.length) return false;
            if (
                this.bodyContent.messageLength === 0 ||
                this.bodyContent.messageLength > 4000
            )
                return false;
            if (this.subject.length === 0 || this.subject.length > 300)
                return false;
            return true;
        },
    },

    methods: {
        chooseFiles() {
            document.getElementById("fileUpload").click();
        },

        clearForm() {
            if (this.mData && this.type == "forwardMail") {
                if (
                    this.mData.domainHolders &&
                    this.mData.domainHolders[this.userInfo.nickName] &&
                    !this.mData.domainHolders[this.userInfo.nickName][0].sender
                )
                    this.sender = this.mData.domainHolders[
                        this.userInfo.nickName
                    ][0].receiver
                        ? this.mData.domainHolders[this.userInfo.nickName][0]
                              .receiver
                        : this.mData.domainHolders[this.userInfo.nickName][0]
                              .cc;
                else this.sender = this.userInfo.nickName;
            } else if (this.mData && this.type == "replyMail")
                this.sender = this.mData.sender;
            else this.sender = "";

            this.receiver = [];
            this.subject = "";
            this.body = "";
            this.attachments = [];
            this.cc = [];
            this.file = {
                inputAttachments: [],
                attachmentsSize: 0,
            };

            this.resetFlag = !this.resetFlag;
            this.bodyContent = {
                delta: undefined,
                messageLength: 0,
            };
            this.file = {
                inputAttachments: [],
                attachmentsSize: 0,
            };
            this.routeBackAfterSent = false;
            this.$refs.composeMail.reset();
        },

        closeForm() {
            this.clearForm();
            this.$emit("discard", this.routeBackAfterSent);
        },

        fetchEntriesDebounced(val) {
            clearTimeout(this.timerId);

            this.timerId = setTimeout(() => {
                this.$store.dispatch("AUTO_FETCH", val);
            }, 500);
        },

        async submit() {
            this.body = this.bodyContent.delta;

            if (
                typeof this.to[this.to.length - 1] === "string" ||
                typeof this.cc[this.cc.length - 1] === "string"
            )
                return this.$toast.error(
                    "Please select the Recipients from the drop down"
                );

            this.to.map((rec) => {
                if (rec.holder) {
                    if (!this.domainHolderMap[rec.holder])
                        this.domainHolderMap[rec.holder] = [];
                    this.domainHolderMap[rec.holder].push({
                        receiver: rec.name,
                    });
                    this.receiver.push(rec.holder);
                } else this.receiver.push(rec.name);
            });

            this.cc.map((rec) => {
                if (rec.holder) {
                    if (!this.domainHolderMap[rec.holder])
                        this.domainHolderMap[rec.holder] = [];
                    this.domainHolderMap[rec.holder].push({ cc: rec.name });
                    this.ccReceiver.push(rec.holder);
                } else this.ccReceiver.push(rec.name);
            });
            /*
      domainHolderMap: {#Hashtagname: [cc: #DomainName, receiver: #DoaminName]}
      */

            this.receiver = Array.from(new Set(this.receiver));
            this.ccReceiver = Array.from(new Set(this.ccReceiver));

            if (this.receiver.length == 0)
                return this.$toast.error(
                    "This message must have at least one recipient."
                );

            if (this.subject.length == 0)
                return this.$toast.error("Missing subject.");

            // if (this.body.length == 0 && this.attached.length == 0)
            //   return this.$toast.error("Missing body.");

            if (this.file.attachmentsSize > 1048576)
                return this.$toast.error(
                    "Attachment excised maximum limit of 1MB"
                );

            this.submitDisable = true;
            this.errorMessage = "";

            const nickNames = this.receiver.concat(
                [...this.ccReceiver, { name: this.userInfo.nickName }].filter(
                    (item) => this.receiver.indexOf(item) < 0
                )
            );

            const nameIndex = nickNames.indexOf(this.userInfo.nickName);
            nickNames.splice(nameIndex, 1);

            for (let index = 0; index < nickNames.length; index++) {
                let element;

                if (typeof nickNames[index] === "string")
                    element = nickNames[index];
                else if (nickNames[index].name) element = nickNames[index].name;
                else element = nickNames[index].holder;

                if (element[0] != "#") {
                    this.$toast.error(
                        `Invalid Hashtag name "${element}". (try using '#' before the name)`
                    );
                    this.submitDisable = false;
                    return;
                } else {
                    nickNames[index] = element;
                }
            }

            for (
                let index = 0;
                index < this.file.inputAttachments.length;
                index++
            ) {
                this.file.inputAttachments[index].size = String(
                    this.file.inputAttachments[index].size
                );

                this.file.inputAttachments[index].data =
                    this.file.inputAttachments[index].base64buf;

                delete this.file.inputAttachments[index].base64buf;
            }
            this.params = {
                sender: this.sender,
                receiver: this.receiver,
                subject: this.subject,
                attachments: this.file.inputAttachments,
                cc: this.ccReceiver,
                secrets: {},
                domainHolders: this.domainHolderMap,
            };

            try {
                if (this.body && this.body.length == 0)
                    this.$toast.error(`Missing body`);
            } catch (error) {
                console.error("error: ", error);
                if (this.file.inputAttachments.length === 0)
                    this.$toast.error(`Missing body`);
            }

            if (this.type == "replyMail")
                this.params.parentId = this.mData.parentId;
            else if (this.type == "forwardMail")
                this.params.previousMails = this.mData.previousMails;

            if (this.userInfo.nickName != this.sender) {
                if (!this.params.domainHolders[this.userInfo.nickName])
                    this.params.domainHolders[this.userInfo.nickName] = [];

                this.params.domainHolders[this.userInfo.nickName].push({
                    sender: this.sender,
                });
            }

            this.params.body = await this.$store.dispatch("DATA_ENCRYPT", {
                message: this.body,
                secret: this.randomString,
            });

            const vueObj = this;
            this.$store
                .dispatch("GET_PUBIC_KEYS", nickNames)
                .then((publicKeys) => {
                    if (!publicKeys) return;

                    let keys = Object.keys(publicKeys);
                    Object.entries(this.domainHolderMap).forEach(
                        ([key, value]) => {
                            if (keys.includes(key))
                                value.forEach((domain) => {
                                    this.params.receiver = this.to.map(
                                        (rec) => rec.name
                                    );
                                    this.params.cc = this.cc.map(
                                        (rec) => rec.name
                                    );

                                    // We need both condition because it may happen that 2 domain name of same user present in both receiver and cc
                                    // Didn't used if-else
                                    if (domain.receiver)
                                        publicKeys[domain.receiver] =
                                            publicKeys[key];

                                    if (domain.cc)
                                        publicKeys[domain.cc] = publicKeys[key];
                                });
                        }
                    );

                    vueObj.sendMail(
                        this.params,
                        publicKeys,
                        vueObj.randomString
                    );
                })
                .catch(() => {
                    this.$toast.error(
                        "Please check you connection and try again later!"
                    );
                    this.submitDisable = false;
                });
        },
        async sendMail(params, emailToPubKeyMap, randomString) {
            // this.$toast(`Sending Secured Mail`);
            const secrets = {};

            const receiver = [];
            const cc = [];

            // if (params.receiver) return (this.submitDisable = false);

            params.receiver = params.receiver
                .toString()
                .toLowerCase()
                .split(",");
            params.cc = params.cc.toString().toLowerCase().split(",");

            // if (params) return (this.submitDisable = false);

            for (const key in emailToPubKeyMap) {
                if (params.receiver.includes(key.toLowerCase()))
                    receiver.push(key);
                if (params.cc.includes(key.toLowerCase())) cc.push(key);

                secrets[key] = await this.$ledgermail_v2.encrypt(
                    randomString,
                    emailToPubKeyMap[key].extPublicKey
                );
            }
            params.receiver = receiver;
            params.cc = cc;
            params.secrets = JSON.stringify(secrets);

            const vueObj = this;

            if (this.type == "replyMail") {
                this.$store
                    .dispatch("REPLY_MAIL", params)
                    .then(() => {
                        vueObj.$store
                            .dispatch("GET_FILTERED_SENT_MAILS", {
                                skipValidation: true,
                                isReply: true,
                            })
                            // .dispatch("MAIL_INIT")
                            .then(() => {
                                vueObj.$toast.success(`Mail Sent`);
                                EventBus.$emit("sentUpdated");
                                vueObj.$router.go(-1);
                            });
                    })
                    .catch((error) => {
                        this.submitDisable = false;
                        console.error("error", error);
                        this.$toast.error(error);
                    });
            } else if (this.type == "forwardMail") {
                this.$store
                    .dispatch("FORWARD_MAIL", params)
                    .then(() => {
                        vueObj.$store
                            .dispatch("GET_FILTERED_SENT_MAILS", {
                                skipValidation: true,
                            })
                            .then(() => {
                                vueObj.$toast.success(`Mail Sent`);
                                EventBus.$emit("sentUpdated");
                                vueObj.$router.go(-1);
                            });
                    })
                    .catch((error) => {
                        this.submitDisable = false;
                        console.error("error", error);
                        this.$toast.error(error);
                    });
            } else {
                this.$store
                    .dispatch("COMPOSE_MAIL", params)
                    .then(() => {
                        vueObj.$store
                            .dispatch("NEW_SENT_MAILS")
                            // .dispatch("MAIL_INIT", { emailId: vueObj.userInfo.emailId })
                            .then(() => {
                                vueObj.$toast.success(`Mail Sent`);
                                EventBus.$emit("sentUpdated");
                                vueObj.closeForm();
                            });
                    })
                    .catch((error) => {
                        this.submitDisable = false;
                        console.error("error", error);
                        this.$toast.error(error);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.m-title {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
</style>
<style lang="scss" scoped>
:deep .v-dialog {
    border-radius: 24px !important;
}

.compose-sen {
    max-width: 200px;
}
</style>
