var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"elevation":"0","icon":"","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-col',{},[_c('v-layout',[_c('v-spacer'),(_vm.routeName == 'user folders')?[_c('v-btn',{staticClass:"mr-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.undoFile()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-up")]),_c('small',[_vm._v("Undo")])],1),(Object.keys(_vm.foldersList).length)?_c('v-menu',{attrs:{"bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark'
                                    ? true
                                    : false}},Object.assign({}, onMenu)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-folder-move ")]),_c('small',[_vm._v(" MoveTo ")])],1)]}}],null,false,449495223)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"200px"}},_vm._l((_vm.foldersList),function(folderName,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.moveToFolder(index, folderName)}}},[_c('v-list-item-title',[_vm._v(_vm._s(folderName))])],1)}),1)],1):_vm._e(),(_vm.mailObject.sender != _vm.userInfo.nickName)?_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.$emit('openReply', 'single')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply")]),_c('small',[_vm._v("Reply")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.$emit('openReply')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply-all")]),_c('small',[_vm._v("Reply All")])],1)]:(_vm.routeName == 'archive')?[_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.unArchiveMail()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-up")]),_c('small',[_vm._v("Undo")])],1)]:[(Object.keys(_vm.foldersList).length)?_c('v-menu',{attrs:{"bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var onMenu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark'
                                    ? true
                                    : false}},Object.assign({}, onMenu)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-folder-move ")]),_c('small',[_vm._v(" MoveTo ")])],1)]}}],null,false,449495223)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"200px"}},_vm._l((_vm.foldersList),function(folderName,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.moveToFolder(index, folderName)}}},[_c('v-list-item-title',[_vm._v(_vm._s(folderName))])],1)}),1)],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false,"elevation":"0"},on:{"click":function($event){return _vm.moveToArchive()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-down")]),_c('small',[_vm._v("Archive")])],1),(_vm.routeName == 'inbox')?_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.$emit('openReply', 'single')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply")]),_c('small',[_vm._v("Reply")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.getters.theme === 'dark' ? true : false},on:{"click":function($event){return _vm.$emit('openReply')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply-all")]),_c('small',[_vm._v("Reply All")])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }