<template>
    <v-container
        fluid
        class="py-1 border"
        :color="$store.getters.theme === 'dark' ? '#000000' : '#ffffff'"
        :dark="$store.getters.theme === 'dark' ? true : false"
    >
        <v-layout justify-center>
            <div class="pa-3 pl-0">
                <v-avatar size="40" class="main-btn mr-3">
                    <strong>{{
                        mailObject.sender
                            ? mailObject.sender.slice(1, 3).toUpperCase()
                            : "##"
                    }}</strong>
                </v-avatar>
            </div>

            <v-card
                class="box pa-3"
                width="100%"
                :class="
                    $store.getters.theme === 'dark'
                        ? 'arrow-left-dark'
                        : 'arrow-left-light'
                "
                :style="{
                    'background-color':
                        $store.getters.theme === 'dark'
                            ? '#000000cc'
                            : '#ffffffcc',
                }"
            >
                <attachment-view
                    v-if="mailObject.files && mailObject.files.length"
                    :files="mailObject.files"
                    :randomString="randomString"
                ></attachment-view>

                <div
                    v-if="!isDecrypting"
                    style="width: 100%; overflow-wrap: break-word"
                >
                    <div class="">
                        <div
                            class="pb-2 mb-1"
                            v-if="mailObject.body.slice(0, 8) == matchString"
                        >
                            <view-rich-text
                                :delta="mailObject.body"
                                :mailObj="mailObject"
                            ></view-rich-text>
                        </div>

                        <div class="pb-2 mb-1" v-else>
                            {{ mailObject.body }}
                        </div>

                        <view-forward-mails
                            v-if="mailObject.mailType == 'forwardMail'"
                            :fwdMail="mailObject.forwardMails"
                            :randomString="mailObject.randomString"
                            :matchString="matchString"
                        ></view-forward-mails>
                    </div>
                    <v-layout>
                        <v-spacer></v-spacer>
                        <mail-footer :mailObject="mailObject"></mail-footer>
                        &nbsp; |
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="$emit('openForwordMail')"
                                    icon
                                    x-small
                                    :dark="
                                        $store.getters.theme === 'dark'
                                            ? true
                                            : false
                                    "
                                    class="ml-2"
                                    target="_blank"
                                    v-on="on"
                                >
                                    <v-icon>mdi-share</v-icon>
                                </v-btn>
                            </template>
                            <span>Forward</span>
                        </v-tooltip>
                        <!-- 
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="openReplyAll"
                                    icon
                                    x-small
                                    :dark="
                                        $store.getters.theme === 'dark'
                                            ? true
                                            : false
                                    "
                                    class="ml-2"
                                    target="_blank"
                                    v-on="on"
                                >
                                    <v-icon>mdi-reply-all</v-icon>
                                </v-btn>
                            </template>
                            <span>Reply All</span>
                        </v-tooltip>

                     -->
                    </v-layout>
                </div>
                <loading-message
                    message="Decrypting mail"
                    v-else
                ></loading-message>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
import attachmentView from "./attachment-view.vue";

import viewForwardMails from "./forward-view.vue";
// import mailHeader from "./fields/mail-header.vue";
import mailFooter from "./fields/mail-footer.vue";
// import timeFormat from "./components/time-format.vue";

import loadingMessage from "./components/loading-message";
import ViewRichText from "../text-editor/ViewRichText";

export default {
    props: [
        "mailObject",
        "isDecrypting",
        "showIds",
        "randomString",
        "collapse",
    ],
    data: () => ({
        showCollapse: false,
        matchString: `{"ops":[`,
    }),
    created() {
        this.showCollapse = this.collapse ? this.collapse : false;
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
    },
    components: {
        ViewRichText,
        attachmentView,
        viewForwardMails,
        loadingMessage,
        // mailHeader,
        mailFooter,
        // timeFormat,
    },
    methods: {
        openReplySingle() {
            const { sender, receiver, cc, subject, domainHolders } =
                this.mailObject;

            this.$emit("openReply", {
                type: "single",
                mailObj: { sender, receiver, cc, subject, domainHolders },
            });
        },
        openReplyAll() {
            const { sender, receiver, cc, subject, domainHolders } =
                this.mailObject;

            this.$emit("openReply", {
                type: "all",
                mailObj: { sender, receiver, cc, subject, domainHolders },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
strong {
    font-weight: 500;
}

.fit-text {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mail-collapse {
    font-size: 0.8em;
}
.box {
    position: relative;
    margin-right: 12px;
    border-radius: 3px;
}

.box.arrow-left-dark:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid #000000cc;
    border-left: none;
    border-bottom: 15px solid transparent;
}
.box.arrow-left-light:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid #ffffffcc;
    border-left: none;
    border-bottom: 15px solid transparent;
}
</style>
