<template>
  <table class="mail-details-dialog white pa-3 popup-table">
    <tr>
      <td class="p-row">From:</td>
      <td>
        <!-- {{ getSingleUserInfo(mailDetails.sender) }} -->
        <span :title="getSingleUserInfo(mailDetails.sender)">{{
          mailDetails.sender
        }}</span>

        <v-btn
          v-if="userInfo.nickName != mailDetails.sender"
          icon
          small
          class="red--text"
          title="block user"
          :disabled="blockedName.includes(mailDetails.sender)"
          @click="openBlockDialog(mailDetails.sender)"
        >
          <v-icon small>mdi-cancel</v-icon>
        </v-btn>
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <v-divider></v-divider>
      </td>
    </tr>

    <tr>
      <td class="p-row">To:</td>
      <td>
        <div v-for="(email, index) in mailDetails.receiver" :key="index">
          <!-- {{ getSingleUserInfo(email) }} -->
          <span :title="getSingleUserInfo(email)">{{ email }}</span>
          <v-btn
            icon
            v-if="userInfo.nickName != email"
            class="red--text"
            title="block user"
            :disabled="blockedName.includes(email)"
            @click="openBlockDialog(email)"
          >
            <v-icon small>mdi-cancel</v-icon>
          </v-btn>
        </div>
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <v-divider></v-divider>
      </td>
    </tr>

    <tr v-if="mailDetails.cc.length">
      <td class="p-row">CC:</td>
      <td>
        <div v-for="(email, index) in mailDetails.cc" :key="index">
          {{ getSingleUserInfo(email) }}

          <v-btn
            icon
            v-if="userInfo.nickName != email"
            class="red--text"
            title="block user"
            :disabled="blockedName.includes(email)"
            @click="openBlockDialog(email)"
          >
            <v-icon small>mdi-cancel</v-icon>
          </v-btn>
        </div>
      </td>
    </tr>

    <tr v-if="mailDetails.cc.length">
      <td colspan="3">
        <v-divider></v-divider>
      </td>
    </tr>

    <tr>
      <td class="p-row">Date:</td>
      <td>
        {{
          new Date(mailDetails.timestamp).toLocaleString("en-US", {
            hour12: true,
          })
        }}
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <v-divider></v-divider>
      </td>
    </tr>

    <tr>
      <td class="p-row">Subject:</td>
      <td>{{ mailDetails.subject }}</td>
    </tr>

    <tr>
      <td colspan="3">
        <v-divider></v-divider>
      </td>
    </tr>

    <tr>
      <td class="p-row">Type:</td>
      <td>{{ getType(mailDetails.mailType) }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["mailDetails", "getMail"],

  created() {
    this.blockedName = this.$store.getters.userInfo.blockedNames;
  },
  data: () => ({
    blockedName: [],
  }),

  computed: {
    userNames() {
      return this.$store.getters.userNames;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // getSenderInfo() {
    //   return this.getSingleUserInfo(this.mailObject.sender);
    // },
    // getReceiverInfo() {
    //   if (this.mailObject.receiver.length == 1)
    //     return this.getSingleUserInfo(this.mailObject.receiver[0]);

    //   let receiver = "";

    //   for (let index = 0; index < this.mailObject.receiver.length; index++) {
    //     receiver =
    //       this.getSingleUserInfo(this.mailObject.receiver[index]) + ", ";
    //   }

    //   return receiver.slice(0, -2);
    // },
  },
  methods: {
    getSingleUserInfo(lookUpName) {
      if (!lookUpName) return "";

      if (lookUpName == this.userInfo.nickName)
        return "Me <" + lookUpName + ">";
      if (this.userInfo.domains.includes(lookUpName))
        return "Me <" + lookUpName + ">";

      lookUpName = lookUpName.toLowerCase();

      const senderDetails = this.userNames[lookUpName];

      if (
        !senderDetails ||
        !senderDetails.firstName ||
        senderDetails.firstName == ""
      )
        return "<" + lookUpName + ">";

      return (
        senderDetails.firstName +
        " " +
        senderDetails.lastName +
        " " +
        "<" +
        lookUpName +
        ">"
      );
    },
    openBlockDialog(name) {
      this.$store.dispatch("SHOW_BLOCKED_USER_POPUP", name);
    },
    getUserName(email) {
      if (!email) return;
      if (email == this.userInfo.nickName) return "me";
      // const user = this.getMail.user_details.filter((i) => i.emailId == email);
      const user = [];

      if (!user.length) return "";
      return user[0].firstName + " " + user[0].lastName;
    },

    getType(mailType) {
      if (mailType == "replyMail") return "secured Reply";
      if (mailType == "newMail" || mailType == "forwardMail")
        return "Secured Mail";
      else return "Secured Mail";
    },
  },
};
</script>

<style scoped>
.p-row {
  min-height: 30px;
}
.popup-table {
  width: 400px;
  max-width: 90vw;
}
</style>