<template>
  <div class="content-view" ref="contentContainer"></div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  props: ["delta", "mailObj"],
  data: () => ({
    content: "",
    smallText: "st",
  }),

  created() {
    let article = document.createElement("article");
    let quill = new Quill(article, { readOnly: true });
    quill.setContents(JSON.parse(this.delta));
    setTimeout(() => {
      this.mailObj.smallText = quill.getText();
      this.$refs.contentContainer.appendChild(article);
    }, 0);
  },
};
</script>
<style lang="scss">
.ql-container {
  font-size: 1rem !important;
}

.content-view {
  .ql-editor {
    padding: 0;
    height: auto;
  }
}
</style>
