<template>
  <section>
    <v-dialog v-model="showDetails" max-width="max-content">
      <popup-details :mailDetails="mailObject"></popup-details>
    </v-dialog>
    <v-layout align-center>
      <span class="">
        <span class="py-0">
          <span class="max-text name-show max-con">
            <span class="from" :title="getSingleUserInfo(mailObject.sender)">
              {{ mailObject.sender }}
            </span>
          </span>
        </span>
      </span>
      <v-btn
        @click="showDetails = true"
        :dark="$store.getters.theme === 'dark' ? true : false"
        small
        min-width="auto"
        elevation="0"
        color="transparent"
        class="pa-0"
        title="view mail details"
      >
        <v-icon> mdi-menu-down </v-icon>
      </v-btn>
      | &nbsp; {{ formatAmPm(new Date(mailObject.timestamp)) }}
      <span v-if="!checkWithIn24Hrs(mailObject.timestamp)">
        &nbsp; {{ new Date(mailObject.timestamp).toLocaleDateString() }}
      </span>
    </v-layout>
  </section>
</template>

<script>
import popupDetails from "./PopupDetails.vue";

export default {
  props: ["mailObject"],
  components: { popupDetails },

  data: () => ({
    showDetails: false,
    isFwd: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userNames() {
      return this.$store.getters.userNames;
    },
    // getSenderInfo() {
    //   return this.getSingleUserInfo(this.mailObject.sender);
    // },
    // getReceiverInfo() {
    //   if (this.mailObject.receiver.length == 1)
    //     return this.getSingleUserInfo(this.mailObject.receiver[0]);

    //   let receiver = "";

    //   for (let index = 0; index < this.mailObject.receiver.length; index++) {
    //     receiver =
    //       this.getSingleUserInfo(this.mailObject.receiver[index]) + ", ";
    //   }

    //   return receiver.slice(0, -2);
    // },
    // getOtherSide() {
    //   if (this.mailObject.sender == this.userInfo.nickName)
    //     return `To: ${this.mailObject.receiver
    //       .toString()
    //       .split(",")
    //       .join(", ")}`;
    //   return `From: ${this.mailObject.sender}`;
    // },
  },

  methods: {
    getSingleUserInfo(lookUpName) {
      if (!lookUpName) return "";

      if (lookUpName == this.userInfo.nickName)
        return "Me <" + lookUpName + ">";
      if (this.userInfo.domains.includes(lookUpName))
        return "Me <" + lookUpName + ">";

      lookUpName = lookUpName.toLowerCase();

      const senderDetails = this.userNames[lookUpName];

      if (
        !senderDetails ||
        !senderDetails.firstName ||
        senderDetails.firstName == ""
      )
        return "<" + lookUpName + ">";

      return (
        senderDetails.firstName +
        " " +
        senderDetails.lastName +
        " " +
        "<" +
        lookUpName +
        ">"
      );
    },
    checkWithIn24Hrs(date) {
      const then = new Date(date);
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates < 24) return true;
      return false;
    },
    formatAmPm(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var amPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + amPm;
      return strTime;
    },
    getUserName(email) {
      if (!email) return;
      if (email == this.userInfo.emailId) return "me";
      const user = [];

      if (!user.length) return email;
      return user[0].firstName + " " + user[0].lastName;
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-collapse {
  .subject {
    font-size: 20px;
    .form {
      font-size: 16px;
    }
  }
}

.name-show {
  position: relative;
  font-size: 14px;
}

.max-con {
  width: max-content;
  max-width: 50vw;
}
.max-text {
  position: relative;
  font-size: 14px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: sub;
}
</style>
