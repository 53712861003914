<template>
  <section style="position: absolute; height: 100%; width: 100%">
    <quill-editor
      style="padding-bottom: 60px; position: relative"
      :style="[
        bodyContent.messageLength > maxLength
          ? { height: 'calc(100% - 50px)' }
          : { height: '100%' },
      ]"
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
    />
    <error v-if="bodyContent.messageLength > maxLength">
      Mail length exceeded maximum allowed length ({{
        bodyContent.messageLength
      }}/{{ maxLength }}).
    </error>
    <v-divider></v-divider>
  </section>
</template>

<script>
import Error from "./Error.vue";

import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  props: ["bodyContent", "resetFlag"],
  components: { quillEditor, Error },
  data: () => ({
    maxLength: "4000",
    content: "",
    editorOption: {
      // debug: "warn",
      theme: "snow",
      scrollingContainer: null,
      placeholder: "",
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline"],
          ["strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          // ["direction"],
          // [{ color: [] }, { background: [] }],
          // [{ align: [] }],
          // ["clean"],
          ["link"],
        ],
      },
    },
  }),
  watch: {
    resetFlag() {
      this.content = "";
    },
    content() {
      this.bodyContent.delta = JSON.stringify(
        this.$refs.myQuillEditor.quill.getContents()
      );
      this.bodyContent.messageLength =
        this.$refs.myQuillEditor.quill.getLength();
    },
  },
};
</script>

<style lang="scss">
.ql-toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  // transform: translateY(100%);
}

.ql .ql-editor {
  height: 100%;
  position: relative;
}

.ql-container {
  font-size: 1rem !important;
  border: none !important;
}

.ql-toolbar {
  display: block;
  left: 50%;
  transform: translate(-50%, -10px);
  border: none;
  border-radius: 5px;
  width: max-content;
  background: white;
  margin: auto;
  box-shadow: 0px 3px 14px 2px #0000001f, 0px 5px 5px -3px #00000033;
  max-width: 90%;

  .ql-formats:last-child {
    margin-right: 0;
  }
}
</style>
