var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_vm._l((_vm.showMails),function(rMail,index){return _c('div',{key:index},[_c('v-row',{attrs:{"justify":[_vm.userInfo.nickName]
                    .concat(_vm.userInfo.domains)
                    .includes(rMail.sender)
                    ? 'start'
                    : 'end'}},[(
                    ![_vm.userInfo.nickName]
                        .concat(_vm.userInfo.domains)
                        .includes(rMail.sender)
                )?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('single-mail-left',{attrs:{"mailObject":rMail,"showIds":true,"isDecrypting":_vm.isDecrypting,"randomString":_vm.randomString,"collapse":true},on:{"openReply":_vm.openReply,"openForwordMail":function($event){return _vm.$emit('openForwordMail', index)}}})],1):_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('single-mail-right',{attrs:{"mailObject":rMail,"showIds":true,"isDecrypting":_vm.isDecrypting,"randomString":_vm.randomString,"collapse":true},on:{"openReply":_vm.openReply,"openForwordMail":function($event){return _vm.$emit('openForwordMail', index)}}})],1)],1)],1)}),(!_vm.showAll && _vm.parentMails && _vm.parentMails.length > _vm.limit)?_c('div',{staticClass:"pa-2 pointer border mx-n8p mb-2 mt-4",class:_vm.$store.getters.theme === 'dark' ? 'black' : 'grey lighten-4',on:{"click":function($event){return _vm.showAllMails()}}},[_c('v-icon',[_vm._v("mdi-chevron-double-down")]),_vm._v(" See "+_vm._s(_vm.parentMails ? _vm.parentMails.length - _vm.limit : "")+" more mail(s). ")],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }