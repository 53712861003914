<template>
  <v-card
    :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
    :dark="$store.getters.theme === 'dark' ? true : false"
    elevation-0
  >
    <v-row justify="center" class="ma-0" align="center">
      <v-col class="text-center">
        <v-btn disabled loading icon x-large target="_blank"> </v-btn>
        <div>
          <button disabled>{{ message }}</button>
          <div class="grey--text">
            <button disabled>Please wait, this might take a while</button>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ["message"],
};
</script>
