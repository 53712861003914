<template>
    <section>
        <div v-for="(rMail, index) in showMails" :key="index">
            <v-row
                :justify="
                    [userInfo.nickName]
                        .concat(userInfo.domains)
                        .includes(rMail.sender)
                        ? 'start'
                        : 'end'
                "
            >
                <v-col
                    class="pb-0"
                    cols="10"
                    v-if="
                        ![userInfo.nickName]
                            .concat(userInfo.domains)
                            .includes(rMail.sender)
                    "
                >
                    <single-mail-left
                        :mailObject="rMail"
                        :showIds="true"
                        :isDecrypting="isDecrypting"
                        :randomString="randomString"
                        :collapse="true"
                        @openReply="openReply"
                        @openForwordMail="$emit('openForwordMail', index)"
                    ></single-mail-left>
                </v-col>
                <v-col cols="10" class="pb-0" v-else>
                    <single-mail-right
                        :mailObject="rMail"
                        :showIds="true"
                        :isDecrypting="isDecrypting"
                        :randomString="randomString"
                        :collapse="true"
                        @openReply="openReply"
                        @openForwordMail="$emit('openForwordMail', index)"
                    ></single-mail-right>
                </v-col>
            </v-row>
        </div>
        <div
            @click="showAllMails()"
            v-if="!showAll && parentMails && parentMails.length > limit"
            class="pa-2 pointer border mx-n8p mb-2 mt-4"
            :class="
                $store.getters.theme === 'dark' ? 'black' : 'grey lighten-4'
            "
        >
            <v-icon>mdi-chevron-double-down</v-icon>
            See {{ parentMails ? parentMails.length - limit : "" }} more
            mail(s).
        </div>
    </section>
</template>

<script>
// import singleMail from "./view-single-mail";
import singleMailRight from "./view-single-mail-right";
import singleMailLeft from "./view-single-mail-sender.vue";

export default {
    props: ["parentMails", "randomString", "noHide"],
    components: { singleMailRight, singleMailLeft },

    data: () => ({
        isDecrypting: false,
        showMails: [],
        showAll: false,
        limit: 4,
    }),

    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
    },

    created() {
        if (this.noHide) return this.showAllMails();

        this.showMails = this.parentMails
            ? this.parentMails.slice(0, this.limit)
            : [];
        this.decryptMails();
    },

    methods: {
        openReply(type = { type: "all" }) {
            this.$emit("openReply", type);
        },
        async decryptMails() {
            this.isDecrypting = true;
            setTimeout(async () => {
                await this.$store.dispatch("DECRYPT_REPLY_MAILS", {
                    mails: this.parentMails,
                    randomString: this.randomString,
                });
                this.isDecrypting = false;
            }, 10);
        },

        showAllMails() {
            this.showAll = true;
            this.showMails = this.parentMails;
            this.decryptMails();
        },
    },
};
</script>

<style lang="scss" scoped>
.file-str {
    padding: 8px 0;
    height: fit-content;

    .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.mail-details {
    max-width: 250px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mail-details-dialog {
    position: relative;
    background: white;
    left: 0;
    top: 0;

    font-size: 0.95rem;
    padding: 5px 10px;
    width: max-content;
    box-shadow: 0 0 15px -10px black;
    margin: 5px auto;

    td {
        padding: 0 0 5px 0;
    }

    // td:nth-last-child(2),
    td:last-child {
        padding-left: 5px;
    }

    td:nth-child(1) {
        vertical-align: top;
        color: #5f6368;
        text-align: right;
    }
}

.mail-body {
    white-space: pre-wrap;
}

.atttachment-placer {
    display: inline-block;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 60vh;
}

.file-viewer {
    width: 80vw;
    height: 80vh;
    position: relative;

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 65vh;
        overflow: auto;

        img {
            height: auto;
            flex-shrink: 0;
            max-width: none;
            user-select: none;
            height: 100%;
        }
    }
}

.featured-button {
    color: #5f6368 !important;
    box-shadow: inset 0 0 0 1px #dadce0 !important;
}
</style>
