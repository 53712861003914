<template>
  <div>
    <div
      class="header-section pt-2 pb-7"
      style="box-shadow: 0px -12px 12px 5px black !important"
    >
      <div class="text-end pr-3">
        <v-btn
          icon
          x-small
          @click="$emit('closeRecipientNotFoundDialog')"
          class="primary"
        >
          <v-icon small color="white">mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        style="letter-spacing: 1px !important; word-spacing: 4px"
        class="text-center text-h5 red--text font-weight-bold"
      >
        Error: Recipient Not Found!
      </div>
    </div>
    <div class="pa-2">
      <!-- <div class="text-center py-4">
        <v-img
          class="mx-auto text-center"
          :src="getReferImage"
          max-width="200"
        ></v-img>
      </div> -->
      <div class="text-center py-2 contents text-body-1 font-weight-medium">
        Invite Recipient to LedgerMail <br />
        for successful email exchange (<a
          target="_blank"
          href="https://ledgermail.io/public/faq.html?q=6"
          class="contents-link text-body-1 font-weight-medium"
          >Read More</a
        >).
      </div>
      <v-divider></v-divider>
      <div class="text-center py-2 contents text-body-1 font-weight-medium">
        Referral Link:
        <v-tooltip top color="grey darken-3" nudge-bottom="5" nudge-width="4">
          <template v-slot:activator="{ on, attrs }">
            <a
              @click="copyReferralLink()"
              v-bind="attrs"
              v-on="on"
              class="contents-link text-body-1 font-weight-medium"
              >{{ referralLink }}
            </a>
          </template>
          <span>{{ copyText }}</span>
        </v-tooltip>
      </div>
      <!-- <div class="text-center py-2 contents text-body-1 font-weight-medium">
        <v-row class="px-4" justify="center" align="center" no-gutters>
          <v-col cols="12">
            <div class="text-body-1 font-weight-medium pb-3">
              Refer on social media
            </div>
          </v-col>
          <v-col
            v-for="social in socialMedia"
            :key="social.media"
            class="px-2"
            cols="auto"
          >
            <ShareNetwork
              style="text-decoration: none"
              :network="social.media"
              :url="referralLink"
              :title="social.message"
              :description="social.media === 'reddit' ? social.descrption : ''"
              :quote="social.quote"
              :hashtags="social.hashtags"
            >
              <div
                v-if="social.media !== 'telegram'"
                class="iconContainer pa-2"
                :style="{ background: social.color }"
              >
                <v-icon class="social-icon">{{ social.icon }} </v-icon>
              </div>
              <v-icon v-else large :color="social.color"
                >{{ social.icon }}
              </v-icon>
            </ShareNetwork>
          </v-col>
        </v-row>
      </div> -->
    </div>
  </div>
</template>
<script>
// import { ShareNetwork } from "../../../../../node_modules/vue-social-sharing/dist/vue-social-sharing";

export default {
  // components: { ShareNetwork },

  data() {
    return {
      copyText: "Copy",
      // socialMedia: [
      //   {
      //     media: "whatsapp",
      //     color: "#25d366",
      //     icon: "mdi-whatsapp",
      //     hashtags: "",
      //     quote: "",
      //     message:
      //       "I'm proud to be part of LedgerMail & contributing to building a Decentralized Email Ecosystem, we deserve! LedgerMail provides the highest level of Security, Privacy, Encryption, and Prevention from email attacks in a Decentralized way.\n Sign up for Free and win 1 $XDC now \n",
      //   },
      //   {
      //     media: "telegram",
      //     color: "#229ED9",
      //     icon: "fa fa-telegram",
      //     hashtags: "",
      //     quote: "",
      //     message:
      //       "I'm proud to be part of LedgerMail & contributing to building a Decentralized Email Ecosystem, we deserve!\n LedgerMail provides the highest level of Security, Privacy, Encryption, and Prevention from email attacks in a Decentralized way. Sign up for Free and win 1 $XDC now",
      //   },
      //   {
      //     media: "messenger",
      //     color: "#00B2FF",
      //     icon: "mdi-facebook-messenger",
      //     hashtags: "",
      //     quote: "",
      //     message: "",
      //   },

      //   {
      //     media: "linkedin",
      //     color: "#0e76a8",
      //     icon: "mdi-linkedin",
      //     hashtags: "ledgermail,ledgermailreferral,emailsecurity",
      //     quote:
      //       "I'm proud to be part of LedgerMail & contributing to building a Decentralized Email Ecosystem, we deserve!\n LedgerMail provides the highest level of Security, Privacy, Encryption, and Prevention from email attacks in a Decentralized way. Sign up for Free and win 1 $XDC now",
      //     message: "",
      //   },
      //   {
      //     media: "twitter",
      //     color: "#00acee",
      //     icon: "mdi-twitter",
      //     hashtags: "ledgermailreferral,imsecure,xinfin",
      //     quote: "",
      //     message:
      //       "I'm proud to be part of #ledgerMail & contributing to building a Decentralized Email Ecosystem, we deserve!\n Experience highest level of Security, Privacy & Encryption with FREE sign-up & Win 1 $XDC \n",
      //   },
      //   // {
      //   //   media: "reddit",
      //   //   color: "#ff4500",
      //   //   icon: "mdi-instagram",
      //   //   hashtags: "",
      //   //   quote: "",
      //   //   message: "Ledgermail a Decentralized Email Ecosystem",
      //   //   description:
      //   //     "I'm proud to be part of LedgerMail & contributing to building a Decentralized Email Ecosystem, we deserve! \n LedgerMail provides the highest level of Security, Privacy, Encryption, and Prevention from email attacks in a Decentralized way. Sign up for Free and win 1 $XDC now",
      //   // },
      // ],
    };
  },
  computed: {
    // getReferImage() {
    //   return require("@/assets/refer.png");
    // },
    referralLink() {
      return (
        "https://mail.ledgermail.io/referral/" +
        this.$store.getters.userInfo.referralCode.toLowerCase()
      );
    },
  },
  methods: {
    copyReferralLink() {
      window.navigator.clipboard
        .writeText(this.referralLink)
        .then(() => {
          this.$toast.success("Referral link copied");
          this.copyText = "Copied";
          setTimeout(() => {
            this.copyText = "Copy";
          }, 5000);
        })
        .catch(() => {
          this.$toast.error("Couldn't copy Link, please try again!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  color: rgba(0, 0, 0, 0.45);
}
.contents-link {
  color: #1a8cff;
  text-decoration: none;
}
.social-icon {
  color: #ffffff;
  font-size: 24px;
}
.iconContainer {
  border-radius: 50%;
}
</style>
