var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.isDecrypting)?_c('v-row',{attrs:{"justify":[_vm.userInfo.nickName]
                .concat(_vm.userInfo.domains)
                .includes(_vm.mailObject.sender)
                ? 'start'
                : 'end'}},[(
                ![_vm.userInfo.nickName]
                    .concat(_vm.userInfo.domains)
                    .includes(_vm.mailObject.sender)
            )?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('single-mail-left',{attrs:{"mailObject":_vm.mailObject,"showIds":true,"isDecrypting":_vm.isDecrypting,"randomString":_vm.randomString,"collapse":true},on:{"openReply":_vm.openReply,"openForwordMail":function($event){return _vm.$emit('openForwordMail', _vm.index)}}})],1):_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('single-mail-right',{attrs:{"mailObject":_vm.mailObject,"showIds":true,"isDecrypting":_vm.isDecrypting,"randomString":_vm.randomString,"collapse":true},on:{"openReply":_vm.openReply,"openForwordMail":function($event){return _vm.$emit('openForwordMail', _vm.index)}}})],1)],1):_c('loading-message',{staticClass:"mt-6",attrs:{"message":"Decrypting mail"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }