<template>
    <v-container v-if="emptyMails" class="fill-height" fluid>
        <v-row justify="center" align="center">
            <v-col class="text-center">
                <v-btn icon large>
                    <v-icon large>mdi-code-tags</v-icon>
                </v-btn>
                <div class="grey--text">
                    <button disabled>You have no mails to show</button>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <section v-else>
        <v-card
            class="glb-border-top mx-3 mt-0"
            :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
            :dark="$store.getters.theme === 'dark' ? true : false"
        >
            <v-container fluid class="pb-0 mb-3">
                <view-controller-btns
                    @openReply="openReply"
                    :mailObject="mailObject"
                    :userInfo="userInfo"
                ></view-controller-btns>
            </v-container>
        </v-card>

        <v-card
            class="mb-5 mx-3 px-8"
            :color="$store.getters.theme === 'dark' ? '#00000099' : '#ffffff99'"
            :dark="$store.getters.theme === 'dark' ? true : false"
        >
            <main class="mail-view-wrapper">
                <template
                    v-if="
                        mailObject.mailType == 'replyMail' &&
                        mailObject.newParentMails
                    "
                >
                    <reply-view
                        @openReply="openReply"
                        @openForwordMail="openForwordMail"
                        :parentMails="mailObject.newParentMails"
                        :randomString="mailObject.randomString"
                        :noHide="true"
                    ></reply-view>
                </template>

                <single-mail
                    :showIds="this.$route.name == 'archive' ? true : false"
                    :mailObject="mailObject"
                    @openReply="openReply"
                    @openForwordMail="openForwordMail"
                    :isDecrypting="isDecrypting"
                    :randomString="mailObject.randomString"
                ></single-mail>

                <template v-if="mailObject.mailType == 'replyMail'">
                    <loading-message
                        v-if="parentMailObj.isFetching"
                        message="Fetching mails"
                    ></loading-message>
                    <reply-view
                        v-else
                        @openReply="openReply"
                        @openForwordMail="openForwordMail"
                        :parentMails="mailObject.parentMails"
                        :randomString="mailObject.randomString"
                    ></reply-view>
                </template>
            </main>
        </v-card>

        <template v-if="replyMailObj.openReply">
            <v-card
                class="mb-5 mx-3"
                :dark="$store.getters.theme === 'dark' ? true : false"
                :color="
                    $store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'
                "
            >
                <reply :rData="replyMailObj"></reply>
            </v-card>
        </template>
        <template v-if="forwordMailObj.openForword">
            <v-card
                class="mb-5 mx-3"
                :dark="$store.getters.theme === 'dark' ? true : false"
                :color="
                    $store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'
                "
            >
                <forward :fData="forwordMailObj"></forward>
            </v-card>
        </template>
    </section>
</template>

<script>
import viewControllerBtns from "./fields/ViewControllerBtns.vue";
import loadingMessage from "./components/loading-message.vue";
import replyView from "./reply-view.vue";

import singleMail from "./view-single-mail.vue";
import forward from "../compose/forward.vue";
import reply from "../compose/reply.vue";

export default {
    components: {
        viewControllerBtns,
        loadingMessage,
        singleMail,
        replyView,
        forward,
        reply,
    },

    data: () => ({
        mailObject: {},
        isDecrypting: false,

        forwordMailObj: {
            subject: "",
            previousMails: [],
            domainHolders: {},
            randomString: "",
            openForword: false,
        },
        replyMailObj: {
            subject: "",
            openReply: false,
            domainHolders: {},
            receivers: [],
            cc: [],
            randomString: "",
            parentId: "",
        },

        parentMailObj: {
            isFetching: false,
        },
    }),

    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
        showMails() {
            return this.$store.getters.userFolderDetails[
                this.$route.params.tagName
            ].mails;
        },
        emptyMails() {
            return this.showMails.length === 0 ? true : false;
        },
    },

    async created() {
        const index = this.showMails.findIndex(
            (mail) => mail.parentId == this.$route.params.id
        );
        // if (index > -1)
        this.decryptMail(this.showMails[index]);
        // else this.$router.push(`/mail/${this.$route.name}`);

        setTimeout(() => {
            this.$store.dispatch("SEEN_MAIL", this.showMails[index]);

            // setTimeout(() => {
            //   if (!this.showMails[index].seen.includes(this.userInfo.nickName))
            //     this.showMails[index].seen.push(this.userInfo.nickName);
            // }, 200);
        }, 10);
    },

    methods: {
        async decryptMail(cypherMail) {
            if (!cypherMail) return;
            this.mailObject = cypherMail;
            if (cypherMail.isDecrypt) {
                this.mailObject = cypherMail;

                return this.getReplyMails();
            }

            this.isDecrypting = true;
            setTimeout(async () => {
                await this.$store.dispatch("DECRYPT_MAIL", cypherMail);
                this.isDecrypting = false;
                this.getReplyMails();
            }, 10);
        },

        getReplyMails() {
            if (
                this.mailObject.mailType != "replyMail" &&
                this.mailObject.parentMails
            )
                return;

            this.parentMailObj.isFetching = true;
            this.$store
                .dispatch("GET_REPLY_MAILS", {
                    id: this.mailObject._id,
                    parentId: this.mailObject.parentId,
                })
                .then((data) => {
                    this.mailObject.parentMails = data;
                    this.separateNew();
                    this.parentMailObj.isFetching = false;
                })
                .catch((message) => {
                    this.$toast.error(message);
                });
        },
        separateNew() {
            const newMails = [];

            this.mailObject.parentMails.forEach((mail) => {
                if (this.mailObject.timestamp < mail.timestamp)
                    return newMails.push(mail);
            });

            this.mailObject.newParentMails = newMails;
            this.mailObject.parentMails.splice(0, newMails.length);
        },

        openReply(type = { type: "all" }) {
            this.forwordMailObj.openForword = false;

            if (type.type == "all") {
                const tempMailObj = type.mailObj
                    ? type.mailObj
                    : this.mailObject;

                this.replyMailObj.receivers = [...tempMailObj.receiver];
                this.replyMailObj.cc = [...tempMailObj.cc];

                if (
                    this.userInfo.nickName != tempMailObj.sender &&
                    !this.userInfo.domains.includes(tempMailObj.sender)
                ) {
                    this.replyMailObj.receivers.push(tempMailObj.sender);

                    if (
                        this.replyMailObj.receivers.includes(
                            this.userInfo.nickName
                        ) ||
                        tempMailObj.domainHolders[this.userInfo.nickName][0]
                            .receiver
                    ) {
                        let index = this.replyMailObj.receivers.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .receiver
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].receiver;

                            let index = this.replyMailObj.receivers.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        }
                    } else {
                        let index = this.replyMailObj.cc.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.cc.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .cc
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].cc;

                            let index = this.replyMailObj.cc.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.cc.splice(index, 1)[0];
                        }
                    }
                } else {
                    this.replyMailObj.sender = tempMailObj.sender;
                }
            } else if (type.type == "single") {
                const tempMailObj = type.mailObj
                    ? type.mailObj
                    : this.mailObject;

                this.replyMailObj.cc = [];
                this.replyMailObj.subject = tempMailObj.subject;

                if (
                    tempMailObj.sender == this.userInfo.nickName ||
                    this.userInfo.domains.includes(tempMailObj.sender)
                ) {
                    this.replyMailObj.receivers = [];
                } else this.replyMailObj.receivers = [tempMailObj.sender];

                if (
                    this.userInfo.nickName != tempMailObj.sender &&
                    !this.userInfo.domains.includes(tempMailObj.sender)
                ) {
                    if (
                        this.replyMailObj.receivers.includes(
                            this.userInfo.nickName
                        ) ||
                        (tempMailObj.domainHolders[this.userInfo.nickName] &&
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .receiver)
                    ) {
                        let index = this.replyMailObj.receivers.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .receiver
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].receiver;
                        }
                    } else {
                        let index = tempMailObj.cc.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender = tempMailObj.cc.splice(
                                index,
                                1
                            )[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .cc
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].cc;
                        }
                    }
                } else {
                    this.replyMailObj.sender = tempMailObj.sender;
                }
            } else if (type.mailObj) {
                const tempMailObj = type.mailObj;

                this.replyMailObj.receivers = [...tempMailObj.receiver];
                this.replyMailObj.cc = [...tempMailObj.cc];

                if (
                    this.userInfo.nickName != tempMailObj.sender &&
                    !this.userInfo.domains.includes(tempMailObj.sender)
                ) {
                    this.replyMailObj.receivers.push(tempMailObj.sender);

                    if (
                        this.replyMailObj.receivers.includes(
                            this.userInfo.nickName
                        ) ||
                        tempMailObj.domainHolders[this.userInfo.nickName][0]
                            .receiver
                    ) {
                        let index = this.replyMailObj.receivers.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .receiver
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].receiver;

                            let index = this.replyMailObj.receivers.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        }
                    } else {
                        let index = this.replyMailObj.cc.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.cc.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .cc
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].cc;

                            let index = this.replyMailObj.cc.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.cc.splice(index, 1)[0];
                        }
                    }
                } else {
                    this.replyMailObj.sender = tempMailObj.sender;
                }
            } else {
                const tempMailObj = this.mailObject;

                this.replyMailObj.receivers = [...tempMailObj.receiver];
                this.replyMailObj.cc = [...tempMailObj.cc];

                if (
                    this.userInfo.nickName != tempMailObj.sender &&
                    !this.userInfo.domains.includes(tempMailObj.sender)
                ) {
                    this.replyMailObj.receivers.push(tempMailObj.sender);

                    if (
                        this.replyMailObj.receivers.includes(
                            this.userInfo.nickName
                        ) ||
                        tempMailObj.domainHolders[this.userInfo.nickName][0]
                            .receiver
                    ) {
                        let index = this.replyMailObj.receivers.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .receiver
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].receiver;

                            let index = this.replyMailObj.receivers.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.receivers.splice(index, 1)[0];
                        }
                    } else {
                        let index = this.replyMailObj.cc.indexOf(
                            this.userInfo.nickName
                        );
                        if (index >= 0)
                            this.replyMailObj.sender =
                                this.replyMailObj.cc.splice(index, 1)[0];
                        else if (
                            tempMailObj.domainHolders[this.userInfo.nickName][0]
                                .cc
                        ) {
                            this.replyMailObj.sender =
                                tempMailObj.domainHolders[
                                    this.userInfo.nickName
                                ][0].cc;

                            let index = this.replyMailObj.cc.indexOf(
                                this.replyMailObj.sender
                            );

                            if (index >= 0)
                                this.replyMailObj.cc.splice(index, 1)[0];
                        }
                    }
                } else {
                    this.replyMailObj.sender = tempMailObj.sender;
                }
            }

            if (
                type.mailObj &&
                type.mailObj.subject &&
                type.mailObj.subject.length
            ) {
                this.replyMailObj.subject = type.mailObj.subject;
                this.replyMailObj.domainHolders = type.mailObj.domainHolders;
            } else {
                this.replyMailObj.subject = this.mailObject.subject;
                this.replyMailObj.domainHolders = this.mailObject.domainHolders;
            }

            this.replyMailObj.randomString = this.mailObject.randomString;
            this.replyMailObj.parentId = this.mailObject.parentId;
            this.replyMailObj.openReply = true;
            this.scrollToBottom();
        },
        openForwordMail(index = -1) {
            this.replyMailObj.openReply = false;

            this.forwordMailObj.previousMails = [];
            if (
                this.mailObject.mailType == "newMail" ||
                this.mailObject.mailType == "forwardMail"
            ) {
                this.forwordMailObj.previousMails.push(this.mailObject._id);
                this.forwordMailObj.subject = this.mailObject.subject;
            } else if (this.mailObject.mailType == "replyMail" && index == -1) {
                const parentMails = [...this.mailObject.parentMails];

                parentMails.forEach((mail) => {
                    this.forwordMailObj.previousMails.push(mail._id);
                });
                this.forwordMailObj.previousMails.reverse();
                this.forwordMailObj.previousMails.push(this.mailObject._id);
                this.forwordMailObj.subject = this.mailObject.subject;
            } else {
                const parentMails = [...this.mailObject.parentMails];
                parentMails.splice(0, index);

                parentMails.forEach((mail) => {
                    this.forwordMailObj.previousMails.push(mail._id);
                });
                this.forwordMailObj.previousMails.reverse();
                this.forwordMailObj.subject =
                    this.mailObject.parentMails[index].subject;
            }

            this.forwordMailObj.domainHolders = this.mailObject.domainHolders;
            this.forwordMailObj.randomString = this.mailObject.randomString;
            this.forwordMailObj.openForword = true;
            this.scrollToBottom();
        },

        scrollToBottom() {
            const el = this.$el.getElementsByClassName("for-scroll")[0];
            setTimeout(() => {
                el.scrollIntoView({ behavior: "smooth" });
            }, 10);
        },
    },
};
</script>

<style lang="scss" scoped>
.file-str {
    padding: 8px 0;
    height: fit-content;

    .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.mail-details {
    max-width: 250px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mail-details-dialog {
    position: relative;
    background: white;
    left: 0;
    top: 0;

    font-size: 0.95rem;
    padding: 5px 10px;
    width: max-content;
    box-shadow: 0 0 15px -10px black;
    margin: 5px auto;

    td {
        padding: 0 0 5px 0;
    }

    // td:nth-last-child(2),
    td:last-child {
        padding-left: 5px;
    }

    td:nth-child(1) {
        vertical-align: top;
        color: #5f6368;
        text-align: right;
    }
}

.mail-body {
    white-space: pre-wrap;
}

.atttachment-placer {
    display: inline-block;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 60vh;
}

.file-viewer {
    width: 80vw;
    height: 80vh;
    position: relative;

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 65vh;
        overflow: auto;

        img {
            height: auto;
            flex-shrink: 0;
            max-width: none;
            user-select: none;
            height: 100%;
        }
    }
}

.featured-button {
    color: #5f6368 !important;
    box-shadow: inset 0 0 0 1px #dadce0 !important;
}

.for-scroll {
    padding-top: 30px;
}
</style>
