<template>
    <v-row class="mb-1">
        <v-col cols="auto" class="">
            <v-btn
                @click="$router.go(-1)"
                elevation="0"
                icon
                :dark="$store.getters.theme === 'dark' ? true : false"
            >
                <!-- :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'" -->
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </v-col>
        <v-col class="">
            <v-layout>
                <v-spacer></v-spacer>
                <template v-if="routeName == 'user folders'">
                    <v-btn
                        @click="undoFile()"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="mr-2"
                    >
                        <v-icon left>mdi-package-up</v-icon>
                        <small>Undo</small>
                    </v-btn>
                    <v-menu
                        bottom
                        :offset-y="true"
                        v-if="Object.keys(foldersList).length"
                    >
                        <template v-slot:activator="{ on: onMenu }">
                            <v-btn
                                elevation="0"
                                color="transparent"
                                :dark="
                                    $store.getters.theme === 'dark'
                                        ? true
                                        : false
                                "
                                v-on="{ ...onMenu }"
                            >
                                <v-icon left> mdi-folder-move </v-icon>
                                <small> MoveTo </small>
                            </v-btn>
                        </template>
                        <v-list
                            style="max-height: 200px"
                            class="overflow-y-auto"
                        >
                            <v-list-item
                                v-for="(folderName, index) in foldersList"
                                :key="index"
                                @click="moveToFolder(index, folderName)"
                            >
                                <v-list-item-title>{{
                                    folderName
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn
                        @click="$emit('openReply', 'single')"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="ml-2"
                        v-if="mailObject.sender != userInfo.nickName"
                    >
                        <v-icon left>mdi-reply</v-icon>
                        <small>Reply</small>
                    </v-btn>
                    <v-btn
                        @click="$emit('openReply')"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="ml-2"
                    >
                        <v-icon left>mdi-reply-all</v-icon>
                        <small>Reply All</small>
                    </v-btn>
                </template>
                <template v-else-if="routeName == 'archive'">
                    <v-btn
                        @click="unArchiveMail()"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="ml-2"
                    >
                        <v-icon left>mdi-package-up</v-icon>
                        <small>Undo</small>
                    </v-btn>
                </template>
                <template v-else>
                    <v-menu
                        bottom
                        :offset-y="true"
                        v-if="Object.keys(foldersList).length"
                    >
                        <template v-slot:activator="{ on: onMenu }">
                            <v-btn
                                elevation="0"
                                color="transparent"
                                :dark="
                                    $store.getters.theme === 'dark'
                                        ? true
                                        : false
                                "
                                v-on="{ ...onMenu }"
                            >
                                <v-icon left> mdi-folder-move </v-icon>
                                <small> MoveTo </small>
                            </v-btn>
                        </template>
                        <v-list
                            style="max-height: 200px"
                            class="overflow-y-auto"
                        >
                            <v-list-item
                                v-for="(folderName, index) in foldersList"
                                :key="index"
                                @click="moveToFolder(index, folderName)"
                            >
                                <v-list-item-title>{{
                                    folderName
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        @click="moveToArchive()"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        elevation="0"
                        class="ml-2"
                    >
                        <v-icon left>mdi-package-down</v-icon>
                        <small>Archive</small>
                    </v-btn>
                    <v-btn
                        @click="$emit('openReply', 'single')"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="ml-2"
                        v-if="routeName == 'inbox'"
                    >
                        <v-icon left>mdi-reply</v-icon>
                        <small>Reply</small>
                    </v-btn>
                    <v-btn
                        @click="$emit('openReply')"
                        elevation="0"
                        color="transparent"
                        :dark="$store.getters.theme === 'dark' ? true : false"
                        class="ml-2"
                    >
                        <v-icon left>mdi-reply-all</v-icon>
                        <small>Reply All</small>
                    </v-btn>
                </template>
            </v-layout>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    props: ["mailObject", "userInfo"],
    computed: {
        ...mapGetters({
            inboxMails: "getInbox",
            sentMails: "getSent",
            archiveMails: "getArchive",
        }),

        routeName() {
            return this.$route.name;
        },

        foldersList() {
            if (this.$route.name == "user folders") {
                const folders = { ...this.$store.getters.foldersList };
                delete folders[this.$route.params.tagName];

                return folders;
            }
            return this.$store.getters.foldersList;
        },

        totalInboxMails: {
            get: function () {
                return this.$store.getters.getInboxTotalMails;
            },
            set: function (value) {
                this.$store.commit("UPDATE_TOTAL_INBOX", value);
            },
        },
        totalSentMails: {
            get: function () {
                return this.$store.getters.getSentTotalMails;
            },
            set: function (value) {
                this.$store.commit("UPDATE_TOTAL_SENT", value);
            },
        },
        totalArchiveMails: {
            get: function () {
                return this.$store.getters.getArchiveTotalMails;
            },
            set: function (value) {
                this.$store.commit("UPDATE_TOTAL_ARCHIVE", value);
            },
        },

        showMails() {
            if (this.$route.params.tagName)
                return this.$store.getters.userFolderDetails[
                    this.$route.params.tagName
                ].mails;
            return {};
        },
    },
    methods: {
        undoFile() {
            let index = this.showMails.findIndex(
                (name) => name._id == this.mailObject._id
            );

            this.$store.getters.userFolderDetails[
                this.$route.params.tagName
            ].mails.splice(index, 1);
            this.$store.getters.userFolderDetails[
                this.$route.params.tagName
            ].totalCount -= 1;

            this.$toast(`Removing mail from Folder`);
            this.$store.dispatch("REMOVE_FROM_ARCHIVE", this.mailObject);

            setTimeout(() => {
                this.$store.dispatch("UPDATE_MAIL", {
                    operationCode: 3,
                    emailObjectId: this.mailObject._id,
                });
            }, 0);

            this.$router.go(-1);
        },
        moveToFolder(tagName, folderName) {
            let index = -2;

            if (this.routeName == "user folders") {
                index = this.$store.getters.userFolderDetails[
                    this.$route.params.tagName
                ].mails.findIndex((name) => name._id == this.mailObject._id);

                this.$store.getters.userFolderDetails[
                    this.$route.params.tagName
                ].mails.splice(index, 1);
                this.$store.getters.userFolderDetails[
                    this.$route.params.tagName
                ].totalCount -= 1;
            } else if (this.routeName == "inbox") {
                index = this.inboxMails.findIndex(
                    (name) => name._id == this.mailObject._id
                );

                this.inboxMails.splice(index, 1);
                this.totalInboxMails = -1;
            } else {
                index = this.sentMails.findIndex(
                    (name) => name._id == this.mailObject._id
                );

                this.sentMails.splice(index, 1);
                this.totalSentMails = -1;
            }

            this.$toast(`Moving this mail to ${folderName}`);
            this.$store.dispatch("MAIL_TO_FOLDER", {
                mail: this.mailObject,
                tagName,
            });

            setTimeout(() => {
                this.$store.dispatch("MOVE_FOLDER", {
                    emailObjectId: this.mailObject._id,
                    folderTag: tagName,
                });
            }, 0);

            this.$router.go(-1);
        },
        moveToArchive() {
            // if (!this.mailObject.archive.includes(this.userInfo.nickName)) {
            //   this.mailObject.archive.push(this.userInfo.nickName);

            //   this.$store.dispatch("SNACKBAR", `Moving this mail to Archive`);
            //   this.$store.dispatch("MAIL_TO_ARCHIVE", this.mailObject);

            //   setTimeout(() => {
            //     this.$store.dispatch("UPDATE_MAIL", {
            //       operationCode: 1,
            //       emailObjectId: this.mailObject._id,
            //     });
            //   }, 10);
            // }
            let index = -2;
            if (this.routeName == "inbox") {
                index = this.inboxMails.findIndex(
                    (name) => name._id == this.mailObject._id
                );

                this.inboxMails.splice(index, 1);
                this.totalInboxMails = -1;
            } else {
                index = this.sentMails.findIndex(
                    (name) => name._id == this.mailObject._id
                );

                this.sentMails.splice(index, 1);
                this.totalSentMails = -1;
            }
            this.$toast(`Moving mail to Archive`);
            this.$store.dispatch("MAIL_TO_ARCHIVE", this.mailObject);

            setTimeout(() => {
                this.$store.dispatch("UPDATE_MAIL", {
                    operationCode: 1,
                    emailObjectId: this.mailObject._id,
                });
            }, 0);

            this.$router.go(-1);
        },

        unArchiveMail() {
            // if (this.mailObject.archive.includes(this.userInfo.nickName)) {
            //   const index = this.mailObject.archive.findIndex(
            //     (name) => name == this.userInfo.nickName
            //   );
            //   this.mailObject.archive.splice(index, 1);

            //   this.$store.dispatch('UPDATE_MAIL', {
            //     operationCode: 2,
            //     emailObjectId: this.mailObject._id,
            //   });
            // }

            let index = this.archiveMails.findIndex(
                (name) => name._id == this.mailObject._id
            );

            this.archiveMails.splice(index, 1);
            this.totalArchiveMails = -1;

            this.$toast(`Removing mail from Archive`);
            this.$store.dispatch("REMOVE_FROM_ARCHIVE", this.mailObject);

            setTimeout(() => {
                this.$store.dispatch("UPDATE_MAIL", {
                    operationCode: 2,
                    emailObjectId: this.mailObject._id,
                });
            }, 0);

            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.back-btn {
    background: #00000080;
    box-shadow: 0px 0px 7px 20px #00000085 !important;
}
</style>
