<template>
  <section>
    <main>
      <input
        type="file"
        id="fileUpload"
        hidden
        multiple
        @change="fileChooser"
      />
    </main>
    <v-container class="py-0" fluid>
      <v-card
        width="100%"
        class="my-1 glb-border-top pb-1"
        v-for="(attachment, index) in [...inputAttachments]
          .reverse()
          .slice(0, showAll ? inputAttachments.length : 3)"
        :key="index"
      >
        <v-row align-content="center" class="mx-0 text-bod-2 attachments my-2">
          <v-col cols="1" class="py-0">
            <v-icon medium> {{ fileIcon(attachment.type) }} </v-icon>
          </v-col>
          <v-col
            class="py-0"
            :class="attachment.size > 1048576 ? 'red--text' : ''"
            cols="7"
            md="8"
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ attachment.name }}
          </v-col>
          <v-col
            cols="4"
            md="3"
            class="py-0 text-end"
            :class="attachment.size > 1048576 ? 'red--text' : ''"
          >
            <span v-if="!attachment.base64buf" class="ml-3">
              <v-progress-circular
                :size="20"
                :value="50"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
            <span v-else class="ml-3">
              {{
                attachment.size > 1024
                  ? attachment.size / 1024 > 1024
                    ? (attachment.size / 1024 / 1024).toFixed(2) + " Mb"
                    : Math.round(attachment.size / 1024) + " Kb"
                  : attachment.size + " B"
              }}
            </span>
            <!-- </v-col>
          <v-col cols="auto" class="py-0"> -->
            <v-icon
              x-small
              @click="removeFile(inputAttachments.length - index - 1)"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row></v-card
      >
      <a v-if="showAll && inputAttachments.length > 3" @click="showAll = false">
        Show less
      </a>
      <a v-if="!showAll && inputAttachments.length > 3" @click="showAll = true">
        + {{ inputAttachments.length - 3 }} more Attachments.
      </a>
      <div class="text-body-2 red--text pt-5" v-if="attachmentsSize > 1048576">
        Total files size (<i>{{
          (attachmentsSize / 1024 / 1024).toFixed(3) + " Mb "
        }}</i
        >) is exceeding Allowed size of 1 Mb
      </div>
    </v-container>
  </section>
</template>

<script>
import buffer from "@/assets/js/file-to-buffer";
import bufferToBase64 from "@/assets/js/buffer-to-base64";

export default {
  props: [
    "inputAttachments",
    "totalAttachmentSize",
    "randomString",
    "resetFlag",
  ],
  data() {
    return {
      showAll: false,
      allAttachments: [],
      tempData: {},
      attachmentsSize: this.totalAttachmentSize,
    };
  },
  watch: {
    resetFlag() {
      this.allAttachments = [];
      this.attachmentsSize = 0;
    },
    inputAttachments() {
      this.attachmentsSize = 0;
      for (let i = 0; i < this.inputAttachments.length; i++) {
        this.attachmentsSize += this.inputAttachments[i].size;
      }

      this.$emit("totalFileSize", this.attachmentsSize);
    },
  },
  methods: {
    fileChooser(list) {
      this.$set(this.tempData, 4, 1);

      for (let i = 0; i < list.target.files.length; i++) {
        for (let j = 0; j < this.inputAttachments.length; j++) {
          if (this.inputAttachments[j].name === list.target.files[i].name) {
            this.inputAttachments.splice(j, 1);
            // this.stringifiedAttachments.splice(j, 1);
          }
        }
        if (!(list.target.files[i].size > 1048576)) {
          this.inputAttachments.push({
            name: list.target.files[i].name,
            size: list.target.files[i].size,
            type: list.target.files[i].type,
          });

          this.fileToString(
            list.target.files[i],
            this.inputAttachments.length - 1
          ).then((data) => {
            this.$set(
              this.inputAttachments[data.index],
              "base64buf",
              data.base64buf
            );
          });
        } else {
          this.inputAttachments.push({
            name: list.target.files[i].name,
            size: list.target.files[i].size,
            type: list.target.files[i].type,
            base64buf: "no data",
          });
        }
      }
    },
    async fileToString(fileToUpload, index) {
      return new Promise((reslove) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileToUpload);
        reader.onloadend = async () => {
          const fileType = fileToUpload.type;
          const prefix = `data:${fileType};base64,`;
          const buf = buffer.Buffer(reader.result);

          // const base64buf = (prefix + bufferToBase64(buf)).toString();
          const base64buf = await this.$store.dispatch("DATA_ENCRYPT", {
            message: (prefix + bufferToBase64(buf)).toString(),
            secret: this.randomString,
          });

          reslove({ base64buf, name: fileToUpload.name, index });
        };
      });
    },
    removeFile(fileIndex) {
      this.inputAttachments.splice(fileIndex, 1);
    },
    fileIcon(type) {
      switch (type) {
        case "image/jpeg":
          return " mdi-file-jpg-box";
        case "image/png":
          return " mdi-file-png-box";
        case "application/pdf":
          return " mdi-file-pdf-box";
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return " mdi-file-word-outline";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "	application/vnd.ms-excel.sheet.binary.macroEnabled.12":
        case "application/vnd.ms-excel":
        case "application/vnd.ms-excel.sheet.macroEnabled.12":
          return "mdi-file-excel-outline";
        case "text/csv":
          return "mdi-file-delimited-outline";
        case "video/3gpp":
        case "video/x-flv":
        case "video/mp4":
        case "application/x-mpegURL":
        case "video/MP2T":
        case "	video/quicktime":
        case "video/x-msvideo":
        case "video/x-ms-wmv":
          return "mdi-file-video-outline";
        case "image/svg+xml":
          return "mdi-svg";
        case "text/html":
          return "mdi-language-html5";
        case "text/css":
          return "mdi-language-css3";
        case "application/x-7z-compressed":
        case "application/zip":
          return "mdi-folder-zip-outline";
        default:
          return "mdi-file-document-outline";
      }
    },
  },
};
</script>
