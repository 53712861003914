<template>
  <section class="pb-1 ">
    <v-dialog v-model="dialog" width="80vw" height="80vh" class="file-viewer">
      <v-card :loading="iframeLoading" class="px-4">
        <v-card-title class="headline grey lighten-2">
          {{ viewData.name }}
        </v-card-title>

        <v-layout
          justify-center
          align-center
          fill-height
          class="file-viewer--content "
        >
          <img :src="viewData.src" alt="Attachment file" />
        </v-layout>

        <v-divider></v-divider>

        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="fileDownload(iframe.name, iframe.src)"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      v-for="(item, i) in files"
      :key="i"
      outlined
      class="ma-3 attachment-viewer"
    >
      <v-list-item class="px-1">
        <v-list-item-content class="pa-0 attach-name">
          <v-list-item-title :title="item.name" class="mb-1 overline">
            {{ item.name }}
          </v-list-item-title>
          <small>
            {{ formatBytes(item.size) }}
          </small>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="pt-0 pb-1 px-0">
        <v-btn
          rounded
          text
          height="20"
          v-show="item.type.split('/').includes('image')"
          @click="viewFile(item)"
        >
          <small>View</small>
        </v-btn>
        <v-btn rounded height="20" text @click="fileDownload(item)">
          <small>Download</small>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-divider></v-divider>
  </section>
</template>

<script>
export default {
  props: ["files", "randomString"],

  data: () => ({
    iframeLoading: false,
    dialog: false,
    viewData: {},
  }),

  methods: {
    async fileDecrypt(file) {
      if (file.src) return;
      this.$toast(`Decrypting File`);
      file.src = await this.$store.dispatch("DATA_DECRYPT", {
        cipherText: file.data,
        secret: this.randomString,
      });
    },
    viewFile(file) {
      this.iframeLoading = true;
      this.dialog = true;
      this.viewData = file;

      setTimeout(async () => {
        await this.fileDecrypt(file);
        this.iframeLoading = false;
      }, 10);
    },
    async fileDownload(file) {
      await this.fileDecrypt(file);
      var fileToDownload = document.createElement("a");
      fileToDownload.href = file.src;
      fileToDownload.download = file.name;
      fileToDownload.click();
    },
    formatBytes(a, b = 2) {
      if (0 === a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
      return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.attach-name {
  font-size: 12px !important;
}
.attachment-viewer {
  max-width: 163px;
  width: max-content;
  border-radius: 4px;
}

.file-viewer {
  width: 80vw;
  height: 80vh;
  position: relative;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 65vh;
    overflow: auto;

    img {
      height: auto;
      flex-shrink: 0;
      max-width: none;
      user-select: none;
      height: 100%;
    }
  }
}
</style>
